(function() {
  "use strict";

  // angular.module('EntrakV5').constant('URL', 'https://portalgql.en-trak.app');
  angular.module("EntrakV5").constant("URL", "https://dev.api.en-trak.com/");
  angular.module("EntrakV5").constant("REFRESH_URL", "https://dev.accounts.en-trak.com/#!/refresh/portal?cb=");
  angular.module("EntrakV5").constant("LOGIN_URL", "https://dev.tenant.en-trak.com/login");
  angular.module("EntrakV5").constant("QR_VISITOR", (function(){
	var tmp = window.location.origin.split(".");
	return "https://dev.visitor.en-trak." + tmp[tmp.length-1] + "/index.html#!/toggleDevice/";
  })());
  angular.module("EntrakV5").constant("COOKIE_PREFIX", "dev_");
  angular.module("EntrakV5").constant("IS_LOCAL", "");
  angular.module("EntrakV5").constant("QR_TOGGLE", "tep://togglenode/");
  angular.module("EntrakV5").constant("MS_CONSENT", "https://login.microsoftonline.com/${tenantId}/adminconsent?client_id=bd971ab3-6cf0-4a1d-9ba2-b815fcd3ae2b&state=secretClientValue");//&redirect_uri=https://ms.en-trak.app/auth/callback
  angular.module("EntrakV5").constant("REACT", "https://dev.tenant.en-trak.com/main/head");
})();
